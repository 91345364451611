.aboutGradientBackground {
    margin-top: 25px;
    background: rgb(6, 43, 112);
    background: linear-gradient(180deg, rgba(6, 43, 112, 1) 30%, rgba(8, 103, 47, 1) 81%, rgba(9, 121, 28, 1) 99%);
}

.misionVisionBackground {
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    opacity: 0.5;
    min-height: 300px;
}